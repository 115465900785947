<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          Einen neuen Kalender anlegen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          class="my-6"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name des Kalenders"
                v-model="title"
                type="text"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-if="helpDataFetched && allCompanies"
                class="align-self-center"
                label="Bitte wähle eine Firma"
                v-model="companies_id"
                item-value="id"
                :items="allCompanies"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.name }}
                </template>

                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.name }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="white--text"
                @click="validate"
                :loading="preloader"
                :disabled="!valid"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CreateEvent",
  props: ["trigger"],
  data() {
    return {
      route: "bonusCalendar",
      preloader: false,
      valid: false,
      title: "",
      companies_id: "",
      helpDataFetched: false,
      allCompanies: [] /* for companyPicker */,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },

  created: async function () {
    /* fetch data for selects and id->name connection */
    let response = await this.getRequest("companies"); // await data from mixin
    this.allCompanies = response.data.data;
    this.helpDataFetched = true;
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.postData();
      }
    },

    async postData() {
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("companies_id", this.companies_id);
      this.preloader = true;
      let response = await this.postRequest(this.route, "", formData);
      this.preloader = false;
      let newPost = response.data.data;
      this.$emit("emit", { action: "createdItem", newPost: newPost });

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Datensatz wurde angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },
  mixins: [apiRequest],
};
</script>
