<template>
  <div id="page">
    <PageHeader
      v-if="!preloader"
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>
    <v-row>
      <v-col cols="12" v-if="!preloader">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :disableSearch="true"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <CreateCalendar
      :trigger="createItem"
      @closeModal="createItem = false"
      @emit="emittedAction"
    />

    <UpdateCalendar
      v-if="editItem && items[itemIndex].id"
      :trigger="editItem"
      :itemData="itemData"
      :id="itemId"
      @closeModal="editItem = false"
    ></UpdateCalendar>

    <v-btn fixed dark fab bottom right color="primary" @click="addItem">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import CreateCalendar from "@/components/addons/bonus_calendar/CreateCalendar.vue";
import UpdateCalendar from "@/components/addons/bonus_calendar/UpdateCalendar.vue";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "BonusCalendar",
  components: {
    PageHeader,
    DataTable,
    CreateCalendar,
    UpdateCalendar,
  },
  data() {
    return {
      pageTitle: "Bonus-Kalender",
      pageDescription: "Verwalte die Bonus-Kalender",
      title: "",
      preloader: false,
      itemId: "",
      itemIndex: "",
      itemData: "",
      editItem: false,
      createItem: false,
      items: [],
      headers: [
        { text: "ID", value: "id", sortable: true, width: "80px" },
        {
          text: "Kunde",
          value: "companiesName",
          sortable: true,
          width: "200px",
        },
        { text: "Titel", value: "title", sortable: true },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          width: "50px",
        },
      ],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest("bonusCalendar");
      this.items = response.data.data;
      this.preloader = false;
    },
    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.items[value.itemIndex];
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },
    addItem() {
      this.createItem = true;
    },
    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll der Kalender wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest("bonusCalendar/" + id); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.note p {
  margin-bottom: 0 !important;
}
</style>
