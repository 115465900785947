<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.firstName }} {{ this.itemData.lastName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row class="my-6">
          <v-col cols="12">
            <Patcher
              :itemValue="itemData.startDate"
              itemName="eventDate"
              itemLabel="Startdatum"
              :hideLabel="false"
              :route="route"
              inputElement="datepicker"
              inputType="date"
              @emit="itemData.startDate = $event.value"
            ></Patcher>
          </v-col>

          <!-- <v-col cols="12">
            <Patcher
              :itemValue="itemData.endDate"
              itemName="eventDate"
              itemLabel="Enddatum"
              :hideLabel="false"
              :route="route"
              inputElement="datepicker"
              inputType="date"
              @emit="itemData.endDate = $event.value"
            ></Patcher>
          </v-col> -->

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.title"
              itemName="title"
              itemLabel="Titel"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.title = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.description"
              itemName="description"
              itemLabel="Beschreibung"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType=""
              @emit="itemData.description = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.image"
              itemName="image"
              itemLabel="Bild"
              :hideLabel="false"
              :route="route"
              inputElement=""
              inputType="media"
              hint="Ideale Bildgröße: 720x240"
              @emit="itemData.image = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.additionalText"
              itemName="additionalText"
              itemLabel="Zusatztext in E-Mail (Platzhalter %bonuscalendar-additional-text%)"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType=""
              @emit="itemData.additionalText = $event.value"
            ></Patcher>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "UpdateCalendarItem",

  components: {
    Patcher,
  },

  props: ["trigger", "itemData", "itemId"],

  data() {
    return {
      title: "",
      route: "bonusCalendar/" + this.itemData.id + "/items/" + this.itemId,
    };
  },
};
</script>
