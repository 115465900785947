<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.firstName }} {{ this.itemData.lastName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row class="my-6">
          <v-col cols="12">
            <Patcher
              :itemValue="itemData.companies_id"
              itemName="companies_id"
              itemLabel="Kunde"
              :hideLabel="false"
              :route="route"
              inputElement="companypicker"
              inputType=""
              @emit="itemData.companies_id = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.title"
              itemName="title"
              itemLabel="Name des Kalenders"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.title = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.description"
              itemName="description"
              itemLabel="Beschreibung"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType=""
              @emit="itemData.description = $event.value"
            ></Patcher>
          </v-col>
          <v-col cols="12">
            <CalendarItems :calendarId="id"></CalendarItems>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import CalendarItems from "@/components/addons/bonus_calendar/CalendarItems.vue";
export default {
  name: "UpdateEvent",

  components: {
    Patcher,
    CalendarItems,
  },

  props: ["trigger", "itemData", "id"],

  data() {
    return {
      title: "",
      route: "bonusCalendar/" + this.itemData.id,
    };
  },
};
</script>
