<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          Einen neuen Eintrag anlegen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          class="my-6"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Startdatum"
                v-model="startDate"
                type="date"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12">
              <v-text-field
                label="Enddatum"
                v-model="endDate"
                type="date"
                :rules="[]"
              ></v-text-field>
            </v-col> -->

            <v-col cols="12">
              <v-text-field
                label="Titel"
                v-model="title"
                type="text"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-container>
                <v-md-editor
                  v-model="description"
                  height="400px"
                  left-toolbar="h bold italic | ul ol"
                  right-toolbar="preview fullscreen"
                  :disabled-menus="['h/h1']"
                  mode="edit"
                ></v-md-editor>
              </v-container>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <span v-if="image">
                    <MediaItemPreview
                      :uuid="image"
                      :showCaption="true"
                      aspectRatio=""
                      width="300"
                      :key="mediaComponentKey"
                    />
                  </span>
                  <v-text-field
                    class="align-self-center p0 m0"
                    v-model="image"
                    type="text"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="white--text mx-1"
                    depressed
                    fab
                    x-small
                    color="red"
                    :disabled="image ? false : true"
                    @click="image = null"
                  >
                    <v-icon dark> mdi-trash-can </v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    color="green"
                    class="white--text"
                    @click="
                      addMediaDialog = true;
                      componentKey += 1;
                    "
                  >
                    {{ $t("plugins.mediapool.title") }}
                    <v-icon right dark> mdi-folder-multiple-image </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="white--text"
                @click="validate"
                :loading="preloader"
                :disabled="!valid"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.title") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <Mediapool
            :selectable="multiple ? 'multiple' : 'one'"
            :selectmode="true"
            :dialogMode="true"
            :key="componentKey"
            :items="image"
            @itemSelected="
              image = $event.uuid;
              addMediaDialog = false;
            "
            @itemAdded="mediaComponentKey++"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Mediapool from "@/views/tech/mediapool/Mediapool";
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
export default {
  name: "CreateCalendarItem",
  props: ["trigger", "calendarId"],
  components: {
    MediaItemPreview,
    Mediapool,
  },
  data() {
    return {
      route: "bonusCalendar/" + this.calendarId + "/items",
      preloader: false,
      valid: false,
      startDate: "",
      endDate: "",
      title: "",
      description: "",
      useForm: true,
      image: null,
      uploading: false,
      componentKey: 0,
      multiple: false,
      itemsSelected: [],
      itemSelected: "",
      addMediaDialog: false,
      mediaComponentKey: 0,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.postData();
      }
    },

    async postData() {
      let formData = new FormData();
      formData.append("startDate", this.startDate);
      //formData.append("endDate", this.endDate);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("useForm", this.useForm);
      formData.append("image", this.image);

      this.preloader = true;
      let response = await this.postRequest(this.route, "", formData);
      this.preloader = false;
      let newPost = response.data.data;
      this.$emit("emit", { action: "createdItem", newPost: newPost });

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Datensatz wurde angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },
  mixins: [apiRequest],
};
</script>
